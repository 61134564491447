<template>
  <information-page button-title="返回" page-title="电子签章授权成功！" van-icon="notes-o" path-name="index" />
</template>
<script>
import InformationPage from '@/components/information-page'
export default {
  components: {
    InformationPage
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style>

</style>
