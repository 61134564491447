var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("information-page", {
    attrs: {
      "button-title": "返回",
      "page-title": "电子签章授权成功！",
      "van-icon": "notes-o",
      "path-name": "index"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }